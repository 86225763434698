export const environment = {
  production: false,
  hmr: false,
  aesysBasePath: 'https://identityserver-experimental.aesys.com',
  infotainmentEngineBasePath: 'https://profound-coyote-193321.appspot.com',
  infotainmentFrontPath: 'https://creo-experimental.aesys.com',
  infotainmentITPlayerPath: 'http://player.creo-experimental.aesys.com',
  infotainmentLoginRedirectPath: 'https://creo-experimental.aesys.com/auth/callback',
  graphql: 'https://profound-coyote-193321.appspot.com/graphql'
};
