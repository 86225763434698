import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import IProgramming = AES.IProgramming;
import ISchedule = AES.ISchedule;
import {BackgroundColorPipe} from '../../../../../../shared/util/background-color.pipe';
import IPlaylist = AES.IPlaylist;
import {ConditionValue} from '../../../../../network/data/condition-value.model';
import ScheduleFrequency = AES.ScheduleFrequency;

@Component({
  selector: 'aesys-tl-simulator-priority',
  templateUrl: './tl-simulator-priority.component.html',
  styleUrls: ['./tl-simulator-priority.component.scss']
})
export class TlSimulatorPriorityComponent {

  @ViewChild('priorityView', {static: false}) priorityView;

  @Output()
  openSchedulesChild = new EventEmitter<{ playlist: IPlaylist, priority: number }>();

  // data
  programmings: IProgramming[] = [];
  filter: ConditionValue[] = [];
  dateStart = 0;
  dateEnd = 0;
  priority = 0;

  //
  totalTime = 0;
  pTime = 0;


  private DAY = 24 * 60 * 60 * 1000;

  constructor(
    private colorPipe: BackgroundColorPipe
  ) {
  }

  public refesh(priority: number, programmings: IProgramming[], filter: ConditionValue[], dateStart: number, dateEnd: number) {
    this.programmings = programmings;
    this.filter = filter;
    this.dateStart = dateStart;
    this.dateEnd = dateEnd;
    this.priority = priority;
    this.totalTime = dateEnd - dateStart;
    this.pTime = 100 / this.totalTime;

    for (const prg of this.programmings) {
      for (const schedule of prg.schedules) {
        let valid = true;

        if (schedule.conditions) {
          const conditions: ConditionValue[][] = JSON.parse(schedule.conditions);
          if (conditions.length > 0 && conditions[0].length > 0) {
            for (const f of this.filter) {
              let check = false;
              for (const c of conditions[0]) {
                if (c.key == f.key && c.value == f.value) {
                  check = true;
                }
              }
              if (!check) {
                valid = false;
              }
            }
          }
        }

        if (!valid) {
          continue;
        }
        switch (schedule.frequency) {
          case ScheduleFrequency.DAILY:
            this.checkDaily(schedule, prg);
            break;
          case ScheduleFrequency.WEEKLY:
            this.checkWeekly(schedule, prg);
            break;
          case ScheduleFrequency.MONTHLY:
            this.checkMonthly(schedule, schedule.monthly_day_of_month, prg);
            break;
          case ScheduleFrequency.YEARLY:
            this.checkYearly(schedule, schedule.yearly_month, prg);
            break;
          case ScheduleFrequency.FIXED:
            this.checkFixed(schedule, prg);
            break;
        }
      }
    }
  }

  private checkSlice(date: Date, schedule: ISchedule, prg: IProgramming) {
    for (const slice of schedule.timeslices) {
      const dstart = new Date(date);
      const dend = new Date(date);
      dstart.setUTCHours(Number(slice.from_time_at.substr(0, 2)));
      dstart.setUTCMinutes(Number(slice.from_time_at.substr(3, 2)));
      const endH = Number(slice.to_time_at.substr(0, 2));
      dend.setUTCHours(endH === 0 ? 24 : endH);
      dend.setUTCMinutes(Number(slice.to_time_at.substr(3, 2)));
      const pStart = this.pTime * (dstart.getTime() - this.dateStart);
      const pWidth = this.pTime * (dend.getTime() - dstart.getTime());
      this.addSliceView(prg, pStart, pWidth, this.getPlaylistView(prg.slug));
    }
  }

  private checkDaily(schedule: ISchedule, prg: IProgramming) {
    const date = this.getTime(schedule);
    while (date[0] < date[1]) {
      const d = new Date(date[0]);
      this.checkSlice(d, schedule, prg);
      date[0] = date[0] + this.DAY;
    }
  }

  private checkWeekly(schedule: ISchedule, prg: IProgramming) {
    const date = this.getTime(schedule);
    while (date[0] < date[1]) {
      const d = new Date(date[0]);
      switch (d.getUTCDay()) {
        case 0:
          if ((schedule.weekly_day_of_week & 1) > 0) {
            this.checkSlice(d, schedule, prg);
          }
          break;
        case 1:
          if ((schedule.weekly_day_of_week & 64) > 0) {
            this.checkSlice(d, schedule, prg);
          }
          break;
        case 2:
          if ((schedule.weekly_day_of_week & 32) > 0) {
            this.checkSlice(d, schedule, prg);
          }
          break;
        case 3:
          if ((schedule.weekly_day_of_week & 16) > 0) {
            this.checkSlice(d, schedule, prg);
          }
          break;
        case 4:
          if ((schedule.weekly_day_of_week & 8) > 0) {
            this.checkSlice(d, schedule, prg);
          }
          break;
        case 5:
          if ((schedule.weekly_day_of_week & 4) > 0) {
            this.checkSlice(d, schedule, prg);
          }
          break;
        case 6:
          if ((schedule.weekly_day_of_week & 2) > 0) {
            this.checkSlice(d, schedule, prg);
          }
          break;
      }
      date[0] = date[0] + this.DAY;
    }
  }


  private checkMonthly(schedule: ISchedule, monthDay: number, prg: IProgramming) {
    const date = this.getTime(schedule);
    while (date[0] < date[1]) {
      const d = new Date(date[0]);
      if (d.getUTCDate() === monthDay) {
        this.checkSlice(d, schedule, prg);
      }
      date[0] = date[0] + this.DAY;
    }
  }

  private checkYearly(schedule: ISchedule, month: number, prg: IProgramming) {
    const date = this.getTime(schedule);
    while (date[0] < date[1]) {
      const d = new Date(date[0]);
      if ((d.getUTCMonth() + 1) === month) {
        while (date[0] < date[1]) {
          const d = new Date(date[0]);
          if (d.getUTCDate() === schedule.yearly_day && (d.getUTCMonth() + 1) === month) {
            this.checkSlice(d, schedule, prg);
          }
          date[0] = date[0] + this.DAY;
        }
      }
      date[0] = date[0] + (this.DAY);
    }
  }

  private checkFixed(schedule: ISchedule, prg: IProgramming) {
    const dstart = new Date();
    const dend = new Date();
    dstart.setUTCFullYear(Number(schedule.from_date_at.substr(0, 4)), (Number(schedule.from_date_at.substr(5, 2)) - 1), Number(schedule.from_date_at.substr(8, 2)));
    dstart.setUTCHours(Number(schedule.fixed_from_time_at.substr(0, 2)));
    dstart.setUTCMinutes(Number(schedule.fixed_from_time_at.substr(3, 2)));

    dend.setUTCFullYear(Number(schedule.to_date_at.substr(0, 4)), (Number(schedule.to_date_at.substr(5, 2)) - 1), Number(schedule.to_date_at.substr(8, 2)));
    dend.setUTCHours(Number(schedule.fixed_to_time_at.substr(0, 2)));
    dend.setUTCMinutes(Number(schedule.fixed_to_time_at.substr(3, 2)));

    if (dend.getTime() < this.dateStart) {
      return;
    }

    if (dstart.getTime() < this.dateStart) {
      dstart.setTime(this.dateStart);
    }

    if (dend.getTime() > this.dateEnd) {
      dend.setTime(this.dateEnd);
    }

    const pStart = this.pTime * (dstart.getTime() - this.dateStart);
    const pWidth = this.pTime * (dend.getTime() - dstart.getTime());

    this.addSliceView(prg, pStart, pWidth, this.getPlaylistView(prg.slug));
  }

  private getPlaylistView(prgSlug: string): any {
    let playlistView = null;
    // è stato inserito un timeout in tl-simulator.ts mettere un do-while per check != null ??
    for (const child of this.priorityView.nativeElement.childNodes) {
      if (child.id === prgSlug) {
        playlistView = child;
      }
    }
    if (playlistView === null) {
      playlistView = document.createElement('div');
      playlistView.id = prgSlug;
      playlistView.style.position = 'relative';
      playlistView.style.width = '100%';
      playlistView.style.minWidth = '100%';
      playlistView.style.height = '26px';
      this.priorityView.nativeElement.appendChild(playlistView);
    }
    return playlistView;
  }

  private addSliceView(prg: IProgramming, pStart: number, pWidth: number, view: any) {
    const scliceView = document.createElement('div');
    scliceView.style.position = 'absolute';
    scliceView.style.height = '20px';
    scliceView.style.left = pStart + '%';
    scliceView.style.top = '3px';
    scliceView.style.width = pWidth + '%';
    scliceView.style.border = '2px solid ' + prg.playlist.color;
    scliceView.style.backgroundColor = this.colorPipe.transform(prg.playlist.color, '0.5');
    scliceView.style.textAlign = 'center';
    scliceView.style.whiteSpace = 'nowrap';
    scliceView.style.overflow = 'hidden';
    scliceView.style.textOverflow = 'ellipsis';
    scliceView.style.color = '#444';
    scliceView.style.cursor = 'pointer';
    // scliceView.innerHTML = '<span style="text-align: center"><img style="width:14px;" src="/assets/images/icn/ic_con_on.png">&nbsp' + prg.playlist.name + '</span>';
    scliceView.innerHTML = prg.playlist.name;
    scliceView.addEventListener('click', () => {
      this.openSchedulesChild.emit({playlist: prg.playlist, priority: this.priority});
    });
    view.appendChild(scliceView);
  }

  private getTime(schedule: ISchedule): [number, number] {
    const ds = new Date();

    ds.setUTCFullYear(Number(schedule.from_date_at.substr(0, 4)));
    ds.setUTCMonth((Number(schedule.from_date_at.substr(5, 2)) - 1));
    ds.setUTCDate(Number(schedule.from_date_at.substr(8, 2)));
    ds.setUTCHours(0);
    ds.setUTCMinutes(0);
    ds.setUTCSeconds(0);

    const de = new Date();
    de.setUTCFullYear(Number(schedule.to_date_at.substr(0, 4)));
    de.setUTCMonth((Number(schedule.to_date_at.substr(5, 2)) - 1));
    de.setUTCDate(Number(schedule.to_date_at.substr(8, 2)));
    de.setUTCHours(23);
    de.setUTCMinutes(59);
    de.setUTCSeconds(59);

    const ts = ds.getTime();
    const te = de.getTime();

    return [ts > this.dateStart ? ts : this.dateStart, te > this.dateEnd ? this.dateEnd : te];
  }

}
